import React, { useEffect, useState } from "react";
import { baseUrl } from "../.."; // Ensure this path is correct

export const GETAPI = async (path) => {
  try {
    const response = await fetch(`${baseUrl}${path}`, {
      method: "GET",
    });
    const data = await response.json();
    if (data && data.status === true) {
      return data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};
