import React from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Near from './Near'
import DocumentTitle from 'react-document-title'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content="Learn more about our hotel in Ujjain. Discover our history, mission, and values." />
        <title>About Us | Agrawal Samaj Ujjain</title>
        {/* Open Graph meta tags */}
        <meta property="og:title" content="About Us | Agrawal Samaj Ujjain" />
        <meta property="og:description" content="Learn more about our hotel in Ujjain. Discover our history, mission, and values." />
        <meta property="og:image" content="https://example.com/about-us.jpg" />
        <meta property="og:url" content="https://agrawalsamajujjain.com/about-us" />
        <meta property="og:type" content="website" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Agrawal Samaj Ujjain" />
        <meta name="twitter:description" content="Learn more about our hotel in Ujjain. Discover our history, mission, and values." />
        <meta name="twitter:image" content="https://example.com/about-us.jpg" />

        {/* Additional meta tags */}
        <meta name="author" content="Agrawal Samaj Ujjain" />
        <meta name="keywords" content="hotel, luxury, Ujjain, history, mission, values" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
      </Helmet>
      {/* <DocumentTitle title='About Us | Agrawal Samaj Ujjain'> */}
      <Header />
      <section class="breadcampwrap" style={{ "background": "url(./assets/images/Breadcamp.png)" }}>
      <div class="container">
        <div class="row align-items-center">
            <div class="breadcamp">
              <h4>Welcome to Dharamshala</h4>
              <h2>Savor valley views from your Dharamshala room.</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est amet quisquam, ullam fugit architecto iusto consequatur. Nam omnis, quibusdam alias maiores rerum veniam architecto laborum nulla excepturi molestias fuga itaque?</p>
            </div>
        </div>
      </div>
    </section>
      <section className="mainpadd_wrapp" style={{ "background": "#f9f9f9" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="aboutdescrbtion">
                <div className="mainheading_wrap">
                  <h6>Explore endless travel</h6>
                  <h2>Your Gateway to Global Adventure</h2>
                </div>
                <p className='aboutDesc'>
                  Welcome to Agrawal Bhawan Dharamshala, your ideal accommodation in the heart of Ujjain. Conveniently located just 1.2 km from the Mahakaleshwar Jyotirling, our dharamshala offers a range of comfortable and affordable options to suit your needs, including: <br />

                  <span className='description'> Two-bedded AC rooms </span><br />
                  <span className='description'> Three-bedded AC rooms  </span> <br />
                  <span className='description'> Four-bedded AC rooms  </span> <br />
                  <span className='description'>   AC dormitory accommodation for up to 16 persons </span><br />
                  Our spacious and clean rooms are perfect for families and groups seeking a restful stay. We prioritize your safety and comfort by providing facilities such as CCTV cameras, hot water, clean drinking water, and extra mattresses.

                  <br /> <span className='description'> Check-in is available from  10:00 AM, and <br/> check-out is at 9:30 AM </span>, ensuring a hassle-free experience for our guests.

                  At Agrawal Bhawan Dharamshala, we strive to offer a welcoming and homely environment, making it the perfect choice for your stay in Ujjain.
                </p>
                {/* <div className="main_btn">
                  <a href="#"
                  >Learn More
                    <img src="assets/images/icons/arrowbuttn.svg" alt=""
                    /></a>
                </div> */}
              </div>
            </div>
            <div className="col-md-7 row">
              <div className="aboutgallery">
                <figure><img src="assets/images/room1.png" alt="" /></figure>
              </div>
              <div className="aboutgallerygrid">
                <div className="ourroomswrap">
                  <figure><img src="assets/images/room.png" alt="" /></figure>
                </div>
                <div className="ourroomswrap ourroomswraptext">
                  <span>
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                    <p>
                      Explore endless travel possibilities and plan your dream
                      getaway with our comprehensive tourism content website
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Near />
      <Footer />
      {/* </DocumentTitle> */}

    </>
  )
}

export default About
