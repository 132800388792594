// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";

// export const postData = createAsyncThunk("api/postData", async (formData) => {
//   const response = await axios.post(
//     // "https://api.lawyerhunt.in/api/Master/AddCourt",
//     formData,
//     {
//       headers: {
//         Authorization:
//           "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIxIiwidXNlcm5hbWUiOiJBZG1pbkBnbWFpbC5jb20iLCJBUElfVElNRSI6MTcxOTAzNDU4Mn0.YxIbqoXZxoHGxUX6YCoqMs3fUHWajpw3RZ4Gvg40FO4",
//         "Content-Type": "multipart/form-data",
//       },
//     }
//   );
//   return response.data;
// });

// const apiSlice = createSlice({
//   name: "api",
//   initialState: {
//     loading: false,
//     data: null,
//     error: "",
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(postData.pending, (state) => {
//         state.loading = true;
//       })
//       .addCase(postData.fulfilled, (state, action) => {
//         state.loading = false;
//         state.data = action.payload;
//         state.error = "";
//       })
//       .addCase(postData.rejected, (state, action) => {
//         state.loading = false;
//         state.data = null;
//         state.error = action.error.message;
//       });
//   },
// });

// export default apiSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

const apiUrl = "https://agrawalsamajujjain.com/api/inquiry"; // Replace with your API endpoint URL

const customHeader = {
  // Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoibmlyamFhLnBhbmRleTA3QGdtYWlsLmNvbSIsIlVzZXJJZCI6IjZhYzY2YzM5LWEwZTctNDdlMS04ZTljLTFjZmQxYThhYTgzOCIsImp0aSI6ImU3NTAyNjNhLWQwOTItNDdjOC05MTFjLTAwYWJkYzY5MjkzZCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlRlc3QiLCJleHAiOjE3MjAxMzgxODksImlzcyI6IklkZW50aXR5IiwiYXVkIjoiSWRlbnRpdHlVc2VyIn0.xLYUqS7x-iYbSkT-6mrLzD8qfUUedC6EjRzw83hqu2E", // Your authorization token
  "Content-Type": "application/json",
  Accept: "text/plain",
};

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming response contains data directly
  } catch (error) {
    // Handle errors
    console.error("Error fetching posts:", error);
    throw error;
  }
});

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;

        if (action.payload.message === "inquerd successfuly") {
          toast.success("Sent successfully");
        }
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default postsSlice.reducer;
