import { configureStore } from "@reduxjs/toolkit";
import apiReducer from "./Inquiry";
import postsSlice from "./Book";
import contactSlice from "./Contact";
// import ViewSlice from './ViewSlice';
const store = configureStore({
  reducer: {
    api: apiReducer,
    posts: postsSlice,
    posts: contactSlice,
  },
});

export default store;
