import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      {/* <!--------FOOTER ----------> */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="footerlogo">
                <h4 className='headinglogo'>
                  Agrawal Samaj Ujjain
                </h4>
                {/* <img src="assets/images/icons/logo.svg" alt="" /> */}
                <p>
                  At Agrawal Samaj Ujjain, we pride ourselves on our commitment to
                  sustainability and community engagement. From our eco-friendly
                  practices to our support for local artisans and businesses, we
                  strive to create a positive impact on both our environment and
                  the community we call home.From the moment you arrive until your departure, our
                  attentive staff is here to cater to your every need, ensuring a stay
                  that exceeds your expectations. </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerlogo">
                <ul>
                  <h4>quick Links</h4>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About us</Link></li>
                  <li><Link to="/room">Rooms</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="footerlogo">
                <ul>
                  <h4>Meet Galary</h4>
                  <li><a href="#">FAQ</a></li>
                  <li><a href="#">Gallery</a></li>
                  <li><a href="#">Menu</a></li>
                  <li><a href="#">Restaurant</a></li>
                </ul>
              </div>
            </div> */}
            <div className="col-md-3">
              <div className="footerlogo">
                <ul>
                  <h4>Instagram</h4>
                  <span>
                    <li><img src="assets/images/room.png" alt="" /></li>
                    <li><img src="assets/images/room1.png" alt="" /></li>
                    <li><img src="assets/images/room.png" alt="" /></li>
                    <li><img src="assets/images/room1.png" alt="" /></li>
                    <li><img src="assets/images/room.png" alt="" /></li>
                    <li><img src="assets/images/room1.png" alt="" /></li>

                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="subfooter">
          <div className="container">
            <div className="copyright">
              <p>@ 2024 all right reserved.</p>
              <p>Developed by : Netspace Software Solution</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
