import React, { useEffect, useState } from 'react'
import { GETAPI } from './Service/Api';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Near = () => {
    const settings = {
        dots: true,
        infinite: false, // Set to false for a single slide
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 initialSlide: 1
    //             }
    //         }
    //     ]
    // };
    const [Data, setData] = useState([]);
    const [testimonial, setTestimonial] = useState([]);
    const getView = async () => {
        try {
            const result = await GETAPI("nearby");
            if (result) {
                setData(result);
            }
            const result1 = await GETAPI("testmonial");
            if (result1) {
                setTestimonial(result1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getView();
    }, []);
    return (
        <>
            {/* <!-- NEAR PLACE --> */}
            <section class="mainpadd_wrapp" style={{ "background": "#1A305F" }}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="mainheading_wrap mainheading_wraphead">
                                <h2 style={{ "color": "white" }}>Near By Places</h2>
                                <p style={{ "color": "white" }}>Discover a haven where four years of unwavering dedication to hospitality excellence meet your every expectation. Nestled in the heart of [Location], our hotel stands as a testament to a journe.. r years of unwavering dedication to hospitality excellence meet your every expectation. Nestled in the heart of [Location], our hotel stands as a testament to a journe.</p>
                                <div class="main_btn">
                                    <a href="#">Learn More
                                        <img src="assets/images/icons/arrowbuttn.svg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                                <div class="wrapnearplaces">
                            {Data.map((item) => (
                                    <div class="nearimageplace">
                                        <figure> <img className='nearImage' src={`https://api.agrawalsamajujjain.com/uploads/nearby/${item.Image}`} alt={item.Title} /></figure>
                                        <div class="neartextwrap">
                                            <h5>{item.Title}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                                            <a href="#">Read More <img src="assets/images/icons/templeread.svg" alt=""></img></a></div>
                                    </div>

))}
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="mainpadd_wrapp" style={{ "background": "#1A305F" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="mainheading_wrap mainheading_wraphead">
                                <h2 style={{ "color": "white" }}>Nearest Places to visit</h2>
                                <p style={{ "color": "white" }}>Ujjain is a historic city located in the state of Madhya Pradesh, India.
                                    It is one of the oldest cities in the country, with a rich cultural and historical significance. Ujjain is known for its religious importance, especially in Hinduism, as it is one of the
                                    seven sacred cities (Saptapuris) where spiritual and religious activities have been prominent for centuries</p>
                                {/* <div className="main_btn">
                                    <a href="#">Learn More
                                        <img src="assets/images/icons/arrowbuttn.svg" alt="" /></a>
                                </div> */}
            {/* </div>
                        </div>

                        <div className="col-md-7">
                            <div className='memo_card1'>
                                <Slider {...settings}>
                                    {Data.map((item) => (
                                        <div key={item.id} className="nearimageplace">
                                            <figure>
                                                <img className='nearImage' src={`https://api.agrawalsamajujjain.com/uploads/nearby/${item.Image}`} alt={item.Title} />
                                            </figure>
                                            <div className="neartextwrap">
                                                <h5>{item.Title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: item.Description }}></p> */}
            {/* <div className='nearRead'>
                                                    <a href="#" >Read More <img src="assets/images/icons/templeread.svg" alt="Read More" /></a>
                                                </div> */}
            {/* </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div> */}
            {/* <div className="col-md-7">
                                <Slider {...settings}>
                            <div className="wrapnearplaces">
                                {Data.map((item) => (
                                    <div key={item.id} className="nearimageplace">
                                            <figure>
                                                <img src={`https://api.agrawalsamajujjain.com/uploads/nearby/${item.Image}`} alt={item.Title} />
                                            </figure>
                                            <div className="neartextwrap">
                                                <h5>{item.Title}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                                                <a href="#">Read More <img src="assets/images/icons/templeread.svg" alt="Read More" /></a>
                                            </div>
                                    </div>
                                ))
                            }

                            </div>
                            </Slider>
                        </div> */}

            {/* </div>
                </div>
            </section > */}
            {/* <!---------------- Our Client ------------------> */}

            <section class="mainpadd_wrapp" style={{ "background": "#f9f8f7" }}>
                <div class="section-padding">
                    <div class="mainheading_wrap mainheading_wrapcenter">
                        <h6>Explore endless travel</h6>
                        <h2>Your Gateway to Global Adventure</h2>
                        <p>
                            Consectetur adipisicing elit. Nihil, illum voluptate eveniet ex
                            fugit ea delectus, sed voluptatem. Laborum accusantium libero
                            commodi id officiis itaque esse adipisci, necessitatibus asperiores.
                            illo odio.
                        </p>
                    </div>
                    <div class="screenshot_slider owl-carousel">
                            <div class="item">
                        {testimonial.map((item) => (
<>
                                <div class="feedbackbody">
                                    <figure>
                                        <img src={`https://api.agrawalsamajujjain.com/uploads/testimonials_image/${item.photo}`} alt={item.name} />
                                    </figure>
                                    <h3>{item.name}</h3>
                                    {/* <p>From Spain</p> */}
                                </div>
                                <div class="testisecondpart">
                                    <figure>
                                        <img src="assets/images/icons/star.svg" alt="" />
                                        <img src="assets/images/icons/star.svg" alt="" />
                                        <img src="assets/images/icons/star.svg" alt="" />
                                        <img src="assets/images/icons/star.svg" alt="" />
                                        <img src="assets/images/icons/star.svg" alt="" />
                                    </figure>
                                    <p>
                                        {item.message}
                                    </p>
                                </div>
                                </>  ))}
                            </div>
                    </div>
                </div>
            </section>
            {/* <section section className="mainpadd_wrapp" style={{ "background": "#f9f8f7" }}>
                <div className="section-padding">
                    <div className="mainheading_wrap mainheading_wrapcenter">
                        <h6>Explore endless travel</h6>
                        <h2>Your Gateway to Global Adventure</h2>
                        <p>
                            Consectetur adipisicing elit. Nihil, illum voluptate eveniet ex
                            fugit ea delectus, sed voluptatem. Laborum accusantium libero
                            commodi id officiis itaque esse adipisci, necessitatibus asperiores.
                            illo odio.
                        </p>
                    </div>
                    <div className="screenshot_slider">
                        <Slider {...settings}>
                            {testimonial.map((item) => (
                                <div key={item.id} className="item">
                                    <div className="feedbackbody">
                                        <figure>
                                            <img src={`https://api.agrawalsamajujjain.com/uploads/testimonials_image/${item.photo}`} alt={item.name} />
                                        </figure>
                                        <h3>{item.name}</h3>
                                        {/* <p>From Spain</p> */}
            {/* </div>
                                    <div className="testisecondpart">
                                        <figure>
                                            <img src="assets/images/icons/star.svg" alt="" />
                                            <img src="assets/images/icons/star.svg" alt="" />
                                            <img src="assets/images/icons/star.svg" alt="" />
                                            <img src="assets/images/icons/star.svg" alt="" />
                                            <img src="assets/images/icons/star.svg" alt="" />
                                        </figure>
                                        <p>
                                            {item.message}
                                        </p>
                                    </div>
                                </div>

                            ))}

                        </Slider>
                    </div>
                </div>
            </section > */}
        </>
    )
}

export default Near
