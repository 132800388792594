import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

const apiUrl = "https://agrawalsamajujjain.com/api/contactus"; // Replace with your API endpoint URL

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming response contains data directly
  } catch (error) {
    // Handle errors
    console.error("Error fetching posts:", error);
    throw error;
  }
});

const contactSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;

        if (action.payload.message === "successfuly") {
          toast.success("Sent successfully");
        }
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
