import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Room from "./components/Room";
import Room_Detail from "./components/Room_Detail";
import Error from "./components/Error";
import About from "./components/About";
import Contact from "./components/Contact";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
function App() {
  const location = useLocation();

  // Function to get the appropriate title and description based on the current path
  const getMetaTags = () => {
    switch (location.pathname) {
      case '/':
        return {
          title: 'Agrawal Samaj Ujjain - Best Place to Stay in Ujjain',
          description: 'Welcome to Agrawal Samaj Ujjain, where you can find the best accommodation in Ujjain.',
        };
      case '/about-us':
        return {
          title: 'About Us - Agrawal Samaj Ujjain',
          description: 'Learn more about our hotel in Ujjain. Discover our history, mission, and values.',
        };
      case '/contact':
        return {
          title: 'Contact Us - Agrawal Samaj Ujjain',
          description: 'Contact our hotel in Ujjain for reservations, inquiries, or feedback.',
        };
      case '/room':
        return {
          title: 'Our Rooms - Agrawal Samaj Ujjain',
          description: 'Explore our luxurious rooms offering stunning views and comfort in Ujjain.',
        };
      case '/room/:id': // Example for dynamic route
        return {
          title: 'Room Details - Agrawal Samaj Ujjain',
          description: 'View details of our deluxe room offering in Ujjain, including amenities and booking information.',
        };
      default:
        return {
          title: 'Agrawal Samaj Ujjain - Page Not Found',
          description: 'Oops! The page you are looking for does not exist.',
        };
    }
  };

  const { title, description } = getMetaTags();
  return (
    <>
      <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          {/* Other default meta tags */}
        </Helmet>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        ></Route>
        <Route
          path="/contact"
          element={<Contact />}
        ></Route>
        <Route
          path="/about-us"
          element={<About />}
        ></Route>
        <Route
          path="/room"
          element={<Room />}
        ></Route>
        <Route
          path="/room/:id"
          element={<Room_Detail />}
        ></Route>
        <Route
          path="*"
          element={<Error />}
        ></Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
