import React from 'react'
import DocumentTitle from 'react-document-title'

const Error = () => {
    return (
        <>
        {/* <DocumentTitle title='ERROR | Agrawal Samaj Ujjain'> */}
            <div>
                <h3>404 page not found</h3>
                <p>The page you are looking for might be removed or is temporarily unavailable</p>
            </div>
            {/* </DocumentTitle> */}
        </>
    )
}

export default Error
