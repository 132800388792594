import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { BookPosts, fetchPosts } from './Redux/Book';
import { useParams } from 'react-router-dom';
import { GETAPI } from './Service/Api';
import { toast } from 'react-toastify';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';

const Room_Detail = () => {
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
   };
   const dispatch = useDispatch();
   const { id } = useParams();
   const [Name, setName] = useState('');
   const [Adult, setAdult] = useState('');
   const [Mobile, setMobile] = useState('');
   const [RoomType, setRoomType] = useState('');
   const [Child, setChild] = useState('');
   const [number, setNumber] = useState('');
   const [fromdate, setFromdate] = useState('');
   const [todate, setTodate] = useState('');
   const [formKey, setFormKey] = useState(0); // Add a state variable for key

   const handleKeyPress = (e) => {
      const charCode = e.which || e.keyCode;
      if (
         charCode < 48 ||
         charCode > 57 ||
         (charCode !== 8 && e.target.value.length >= 10)
      ) {
         e.preventDefault();
      }
   };
   const status = useSelector((state) => state.posts.status);
   const error = useSelector((state) => state.posts.error);
   const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('name', Name);
      formData.append('mobile', Mobile);
      formData.append('adult', Adult);
      formData.append('child', Child);
      formData.append('RoomType', RoomType);
      formData.append('no_of_room', number);
      formData.append('booking_from_date', fromdate);
      formData.append('booking_to_date', todate);

      try {
         await dispatch(BookPosts(formData));
         toast.success('Booking successful');
         setName('');
         setAdult('');
         setMobile('');
         setRoomType('');
         setChild('');
         setNumber('');
         setFromdate('');
         setTodate('');
         setFormKey(formKey + 1);
      } catch (error) {
         toast.error(`Booking failed: ${error.message}`);
      }
   };
   // const handleSubmit = async (e) => {
   //    e.preventDefault();
   //    const formData = new FormData();
   //    formData.append('name', Name);
   //    formData.append('mobile', Mobile);
   //    formData.append('adult', Adult);
   //    formData.append('child', Child);
   //    formData.append('RoomType', RoomType);
   //    formData.append('no_of_room', number);
   //    formData.append('booking_from_date', fromdate);
   //    formData.append('booking_to_date', todate);


   //    dispatch(BookPosts(formData)).then((response) => {

   //       setName('');
   //       setAdult('');
   //       setMobile('');
   //       setRoomType('');
   //       setChild('');
   //       setNumber('');
   //       setFromdate('');
   //       setTodate('');
   //    }).catch((error) => {
   //       toast.error(`Form submission failed: ${error.message}`);
   //     });
   //    };
   const [Data, setData] = useState([]);

   const getView = async () => {
      try {
         const result = await GETAPI(`roombyid/${id}`);
         if (result) {
            setData(result);
         }
      } catch (error) {
         console.error("Error fetching data:", error);
      }
   };

   useEffect(() => {
      getView();
   }, []);
   return (
      <>
         {/* <DocumentTitle title='Room Details | Agrawal Samaj Ujjain'> */}
         <Helmet>
            <meta name="description" content="Learn more Room Details our hotel in Ujjain. Discover our history, mission, and values." />
            <title>Room Details | Agrawal Samaj Ujjain</title>
            {/* Open Graph meta tags */}
            <meta property="og:title" content="Room Details | Agrawal Samaj Ujjain" />
            <meta property="og:description" content="Learn more Room Details our hotel in Ujjain. Discover our history, mission, and values." />
            <meta property="og:image" content="https://example.com/RoomDetails.jpg" />
            {/* <meta property="og:url" content="https://agrawalsamajujjain.com/RoomDetails" /> */}
            <meta property="og:type" content="website" />

            {/* Twitter meta tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Room Details | Agrawal Samaj Ujjain" />
            <meta name="twitter:description" content="Learn more Room Details our hotel in Ujjain. Discover our history, mission, and values." />
            <meta name="twitter:image" content="https://example.com/RoomDetails-us.jpg" />

            {/* Additional meta tags */}
            <meta name="author" content="Agrawal Samaj Ujjain" />
            <meta name="keywords" content="hotel, luxury, Ujjain, history, mission, values" />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
         </Helmet>
         <Header />
         <section className="breadcampwrap">
            <div className="container">
               <div className="row align-items-center">
                  <div className="breadcamp">
                     <h4>Welcome to Dharamshala</h4>
                     <h2>Savor valley views from your Dharamshala room.</h2>
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est amet quisquam, ullam fugit architecto iusto consequatur. Nam omnis, quibusdam alias maiores rerum veniam architecto laborum nulla excepturi molestias fuga itaque?</p>
                  </div>
               </div>
            </div>
         </section>
         <section className="atharva_details mainpadd_wrapp">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="mainheading_wrap" >
                        <h6>LUXURY EXPERIENCE</h6>
                        <h2>Global Adventure</h2>
                     </div>
                  </div>
                  {Data.length > 0 && Data.map((item) => (
                     <div className="col-lg-8 col-sm-12 ">
                        <div className="productViewSlider">
                           <Slider {...settings}>
                              {item.Images && item.Images.length > 0 ? (
                                 item.Images.map((image, index) => (
                                    <div className="proViewSingle" key={index}>
                                       <div className="proViewSingleImg" data-hash={`proViewSingleImg-${index + 1}`}>
                                          <img
                                             src={`https://api.agrawalsamajujjain.com/uploads/Room/${image.TumImage}`}
                                             alt={item.Title}
                                          />
                                          <div className="price_label">
                                             <strong><span>{`${item.Price} ₹`}</span> per night</strong>
                                          </div>
                                       </div>
                                    </div>
                                 ))
                              ) : (
                                 <div className="proViewSingle">
                                    <div className="proViewSingleImg" data-hash="proViewSingleImg-1">
                                       <img
                                          src={`https://api.agrawalsamajujjain.com/uploads/Room/${item.TumImage}`}
                                          alt={item.Title}
                                       />
                                       <div className="price_label">
                                          <strong><span>{`${item.Price} ₹`}</span> per night</strong>
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </Slider>



                           {/* <Slider {...settings}>

                              <div className="proViewSingle">
                                 <div className="proViewSingleImg" data-hash="proViewSingleImg-1">
                                    <img
                                       src={`https://api.agrawalsamajujjain.com/uploads/Room/${item.TumImage}`}
                                       alt={item.Title}
                                    />
                                    <div className="price_label">
                                       <strong><span>{item.Price}</span> per night</strong>
                                    </div>
                                 </div>
                              </div>


                              {/* <div className="proViewSingle">
                              <div className="proViewSingleImg" data-hash="proViewSingleImg-2">
                                 <img src="/assets/images/room.png" alt="" />
                                 <div className="price_label">
                                    <strong><span>₹49</span> per night</strong>
                                 </div>
                              </div>
                           </div> */}



                           {/* </Slider> */}
                           {/* Example of list view */}
                           {/* <div className="proViewList">
                              <a>
                                 <img src="/assets/images/room.png" alt="" />
                              </a>
                              <a>
                                 <img src="/assets/images/room.png" alt="" />
                              </a>
                           </div> */}
                        </div>
                        <div className="atharva_description">
                           <h4>Description</h4>
                           <p>{item.Description}</p>
                        </div>
                        <div className="atharva_description">
                           <h4>Facility</h4>
                           <p>{item.Fasalitys}</p>
                        </div>
                     </div>
                  ))}
                  <div className="col-lg-4">
                     <div className="col-lg-12">
                        <form key={formKey} onSubmit={handleSubmit}>
                           <div className="form atharva_side_filter">
                              <h3>Book a Rooms</h3>
                              <div className="form-group">
                                 <label for="RoomsSelect">Name</label>
                                 <input type="text" value={Name}
                                    className="form-control" onChange={(e) => setName(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="RoomsSelect">Mobile</label>
                                 <input value={Mobile} type="text" onKeyPress={handleKeyPress} minLength={10} className="form-control" onChange={(e) => setMobile(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="checkIn">CHECK IN</label>
                                 <input value={fromdate} type="date" className="form-control" onChange={(e) => setFromdate(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="checkOut">CHECK Out</label>
                                 <input value={todate} type="date" className="form-control" onChange={(e) => setTodate(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="RoomsSelect">Rooms Type</label>
                                 <input value={RoomType} type="text" className="form-control" onChange={(e) => setRoomType(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="RoomsSelect">Rooms</label>
                                 <input value={number} type="text" className="form-control" onChange={(e) => setNumber(e.target.value)} />
                              </div>
                              <div className="form-group">
                                 <label for="exampleFormControlSelect1">Guests</label>
                                 <br />
                                 <label for="exampleFormControlSelect1">Adult</label>
                                 <input value={Adult} type="text" className="form-control" onChange={(e) => setAdult(e.target.value)} />
                                 <label for="exampleFormControlSelect1">Child</label>
                                 <input value={Child} type="text" className="form-control" onChange={(e) => setChild(e.target.value)} />
                              </div>
                              <div className="col-lg-12 col-md-12 ">
                                 <div className="book_btn">
                                    <button type="submit" disabled={status === 'loading'}>Book</button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
         {/* </DocumentTitle> */}
      </>
   )
}

export default Room_Detail
