import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Near from './Near'
import { GETAPI } from './Service/Api';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
const Room = () => {

  const [Data, setData] = useState([]);

  const getView = async () => {
    try {
      const result = await GETAPI("room");
      if (result) {
        setData(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getView();
  }, []);

  return (
    <>
      {/* <DocumentTitle title='Room | Agrawal Samaj Ujjain'> */}
      <Helmet>
        <meta name="description" content="Learn more about our hotel Room in Ujjain. Discover our history, mission, and values." />
        <title>Room | Agrawal Samaj Ujjain</title>
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Room | Agrawal Samaj Ujjain" />
        <meta property="og:description" content="Learnmore about our hotel Room in Ujjain. Discover our history, mission, and values." />
        <meta property="og:image" content="https://example.com/Room-us.jpg" />
        <meta property="og:url" content="https://agrawalsamajujjain.com/room" />
        <meta property="og:type" content="website" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Room | Agrawal Samaj Ujjain" />
        <meta name="twitter:description" content="Learn more about our hotel Room in Ujjain. Discover our history, mission, and values." />
        <meta name="twitter:image" content="https://example.com/Room-us.jpg" />

        {/* Additional meta tags */}
        <meta name="author" content="Agrawal Samaj Ujjain" />
        <meta name="keywords" content="hotel, luxury, Ujjain, history, mission, values" />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
      </Helmet>
        <Header />
        {/* <!----------------HERO SECTION------------------> */}
        <section className="breadcampwrap" style={{ "background": "url('./assets/images/Breadcamp.png') no-repeat ", "backgroundSize": 'cover' }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="breadcamp">
                <h4>Welcome to Dharamshala</h4>
                <h2>Savor valley views from your Dharamshala room.</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est amet quisquam, ullam fugit architecto iusto consequatur. Nam omnis, quibusdam alias maiores rerum veniam architecto laborum nulla excepturi molestias fuga itaque?</p>
              </div>
            </div>
          </div>
        </section>
        <section className="mainpadd_wrapp" style={{ "background": "#f9f9f9" }}>
          <div className="container">
            <div className="row">
              <div className="headingwrapflex">
                <div className="mainheading_wrap">
                  <h6>Explore endless travel</h6>
                  <h2>Your Gateway to Global Adventure</h2>
                </div>
                <div className="buttonmore">
                  <a href="">View More...</a>
                </div>
              </div>
            </div>
            <div className="roomswrapgrid">
              {Data.length > 0 && Data.map((item) => (
                <Link to={`/room/${item.id}`}>
                  <div key={item.id} className="memo_card">
                    <div className="readwithImg">
                      <figure>
                        <img
                          src={`https://api.agrawalsamajujjain.com/uploads/Room/${item.TumImage}`}
                          alt={item.Title}
                        />
                      </figure>
                    </div>
                    <div className="card_text">

                      <a href="#">
                        <h3>{item.Title || "unveiling the World's Best Hotels for Unforgettable ..."}</h3>
                      </a>
                      <h6>{item.Price} ₹</h6>
                      <div className="bookwrap">
                        {/* <span>
                      <img src="assets/images/icons/heart.svg" alt="" />8 days
                    </span> */}
                        <div className="bookbutton">
                          <Link to={`/room/${item.id}`}>
                            Book Now
                            <img src="assets/images/icons/arrowbook.svg" alt="Book ARROW" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </section>
        <Near />
        <Footer />
      {/* </DocumentTitle> */}
    </>
  )
}

export default Room
