import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
        <header>
      <div className="row headerfixed">
        <div className="headerwrap">
          <div className="logo">
            <h4 className='headinglogo'>
              Agrawal Samaj Ujjain
            </h4>
            {/* <img src="assets/images/icons/logo.svg" alt="LOGO" /> */}
          </div>
          <nav className="myNav">
            <ul>
              <div className="closeMe">
                <button className="hideMe">
                  <img src="assets/images/icons/close.svg" alt="" />
                </button>
              </div>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/room">Rooms</Link></li>
              {/* <li><a href="#">Dining</a></li> */}
              <li><Link to="/contact">Contact us</Link></li>
            </ul>
          </nav>
          <div className="openMenu">
            <img
              src="https://www.tripujjain.com/assets/frontend/images/icons/icon-menu.webp"
              alt="ICON"
            />
          </div>
          <div className="searchhead">
            {/* <img src="assets/images/icons/searchheader.svg" alt="" /> */}
            <a href="tel:07342554716">
              <p>0734-255 4716</p>
            </a>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header
