// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { toast } from "react-toastify";

// const initialState = {
//   posts: [],
//   status: "idle",
//   error: null,
// };

// const apiUrl = "https://agrawalsamajujjain.com/api/booking"; // Replace with your API endpoint URL

// const customHeader = {
//   "Content-Type": "application/json",
//   Accept: "text/plain",
// };

// export const BookPosts = createAsyncThunk("posts/BookPosts", async (body) => {
//   try {
//     const response = await axios.post(apiUrl, body, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     });
//     return response.data; // Assuming response contains data directly
//   } catch (error) {
//     // Handle errors
//     console.error("Error fetching posts:", error);
//     throw error;
//   }
// });

// const BookSlice = createSlice({
//   name: "posts",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(BookPosts.pending, (state) => {
//         state.status = "loading";
//       })
//       .addCase(BookPosts.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.posts = action.payload;

//         // Check if message is "Booking Successfully" and show success toast
//         if (action.payload.message === "Booking Successfully") {
//           alert("Booking Done successfully");
//         }
//       })
//       .addCase(BookPosts.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.error.message;
//       });
//   },
// });

// export default BookSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// Initial state for the slice
const initialState = {
  posts: [],
  status: "idle",
  error: null,
};

// API URL
const apiUrl = "https://agrawalsamajujjain.com/api/booking"; // Replace with your API endpoint URL

// Custom headers for the API request
const customHeader = {
  "Content-Type": "application/json",
  Accept: "text/plain",
};

// Async thunk to book posts
export const BookPosts = createAsyncThunk("posts/BookPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: customHeader,
    });
    return response.data; // Assuming response contains data directly
  } catch (error) {
    // Handle errors
    console.error("Error booking posts:", error);
    throw error;
  }
});

// Slice to manage posts state
const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(BookPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(BookPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;

        // Check if message is "Booking Successfully" and show success toast
        // if (action.payload.message === "Booking Successfully") {
        //   toast.success("Booking done successfully");
          
        // }
      })
      .addCase(BookPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default postsSlice.reducer;
