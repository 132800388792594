import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import Near from './Near'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchPosts } from './Redux/Inquiry';
import { useDispatch, useSelector } from 'react-redux';
import { GETAPI } from './Service/Api';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
const Home = () => {
  const settings = {
    dots: true,
    infinite: false, // Set to false for a single slide
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  const [Name, setName] = useState('');
  const [Message, setMessage] = useState('');
  const [Mobile, setMobile] = useState('');
  const [RoomType, setRoomType] = useState('');
  const dispatch = useDispatch();
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Name', Name);
    formData.append('Mobile', Mobile);
    formData.append('Message', Message);
    formData.append('RoomType', RoomType);
    // formData.append('language', 'English');
    dispatch(fetchPosts(formData));
    setMessage('');
    setName('');
    setRoomType('');
    setMobile('');
  };

  const [Data, setData] = useState([]);

  const getView = async () => {
    try {
      const result = await GETAPI("room");
      if (result) {
        setData(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getView();
  }, []);
  return (
    <>
      {/* <DocumentTitle title='Home | Agrawal Samaj Ujjain'> */}
        <Header />
        {/* <!----------------HERO SECTION------------------> */}
        <section className="herowrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-8">
                <div className="welcomebody">
                  <h4>Welcome to Agrawal Samaj Ujjain</h4>
                  <h1>Offering a comfortable and affordable stay with all the modern amenities you need</h1>
                  <div className="main_btn">
                    <a href="/about">Learn More
                      <img src="assets/images/icons/arrowbuttn.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="formInquiry">
                  <h5>Get In Touch</h5>
                  <p>
                    Connect with us easily - Get in touch for all your inquiries and
                    bookings.
                  </p>
                  <div className="formCard">
                    <form onSubmit={handleSubmit}>
                      <div className="inline-fields">
                        <div className="form-group">
                          <input
                            placeholder="Full Name"
                            className="form-control"
                            type="text"
                            name="Name"
                            id="name"
                            value={Name}
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            placeholder="Room Type"
                            className="form-control"
                            type="text"
                            id="RoomType"
                            value={RoomType}
                            name="RoomType"
                            onChange={(e) => setRoomType(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Enter Mobile Number"
                          className="form-control"
                          type="text"
                          name="Mobile"
                          value={Mobile}
                          id="mobile_no"
                          minLength={10}
                          onKeyPress={handleKeyPress}
                          required
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="Details"
                          className="form-control"
                          placeholder="Enter Message Here"
                          id="description"
                          required
                          value={Message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn-started" id="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!---------------- ABOUT DHARAMSHALA ------------------> */}
        <section className="mainpadd_wrapp" style={{ "background": "#f9f9f9" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="aboutdescrbtion">
                  <div className="mainheading_wrap">
                    <h6>Explore endless travel</h6>
                    <h2>Your Gateway to Global Adventure</h2>
                  </div>
                  <p>
                    Our hotel features 30 beautifully appointed rooms and suites,
                    each designed with your comfort in mind. Whether you are visiting for
                    business or leisure, our accommodations provide a serene and luxurious
                    retreat. Enjoy amenities such as [ High-speed Wi-Fi, flat-screen TVs, plush bedding, and spacious bathrooms].
                    We invite you to experience the warm hospitality and luxurious comfort of Agrawal Samaj Ujjain.
                    Book your stay with us today and discover why our guests return time
                    and time again. We look forward to welcoming you to our family and making your visit truly unforgettable.
                  </p>
                  <div className="main_btn">
                    <a href="#"
                    >Learn More
                      <img src="assets/images/icons/arrowbuttn.svg" alt=""
                      /></a>
                  </div>
                </div>
              </div>
              <div className="col-md-7 row">
                <div className="aboutgallery">
                  <figure><img src="assets/images/room1.png" alt="" /></figure>
                </div>
                <div className="aboutgallerygrid">
                  <div className="ourroomswrap">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                  </div>
                  <div className="ourroomswrap ourroomswraptext">
                    <span>
                      <figure><img src="assets/images/room.png" alt="" /></figure>
                      <p>
                        Explore endless travel possibilities and plan your dream
                        getaway with our comprehensive tourism content website
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!---------------- OUR GALLERY ------------------> */}
        <section className="mainpadd_wrapp">
          <div className="container">
            <div className="headingwrapflex">
              <div className="mainheading_wrap">
                <h6>Explore endless travel</h6>
                <h2>Your Gateway to Global Adventure</h2>
              </div>
              <div className="buttonmore">
                <a href="">View More...</a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9 row">
                <div className="col-md-4">
                  <div className="galleryimgwrap">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="galleryimgwrap">
                    <figure><img src="assets/images/room1.png" alt="" /></figure>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="galleryimgwrap">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="galleryimgwrap">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="galleryimgwrap">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="col-md-12">
                  <div className="gallerycontentright">
                    <figure><img src="assets/images/room.png" alt="" /></figure>
                    <div className="activties_event">
                      <h6>Activities event</h6>
                      <h3>
                        Unlock Special Savings: Up to Off Your Good Adventure!
                      </h3>

                      <div className="main_btn">
                        <a href="#"
                        >Learn More </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!---------------- OUR GALLERY ------------------> */}

        <section className="mainpadd_wrapp" style={{ "background": "#f9f9f9" }}>
          <div className="container">
            <div className="row">
              <div className="headingwrapflex">
                <div className="mainheading_wrap">
                  <h6>Explore endless travel</h6>
                  <h2>Your Gateway to Global Adventure</h2>
                </div>
                <div className="buttonmore">
                  <a href="">View More...</a>
                </div>
              </div>
            </div>
          </div>
          <div className="memory_card_slider">
            <Slider {...settings}>
              {Data.map((item) => (
                <div key={item.id} className="memo_card">
                  <div className="readwithImg">
                    <figure><img src={`https://api.agrawalsamajujjain.com/uploads/Room/${item.TumImage}`}
                      alt={item.Title} /></figure>
                  </div>
                  <div className="card_text">

                    <a href="#">
                      <h3>{item.Title || "unveiling the World's Best Hotels for Unforgettable ..."}</h3>
                    </a>
                    <h6>{item.Price} ₹</h6>
                    <div className="bookwrap">
                      <span
                      ><img src="assets/images/icons/heart.svg" alt="" />8 days</span>
                      <div className="bookbutton">
                        <Link to={`/room/${item.id}`}
                        >Book Now
                          <img src="assets/images/icons/arrowbook.svg" alt="Book ARROW"
                          /></Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </Slider>

          </div>
        </section>
        <Near />
        <Footer />
          {/* </DocumentTitle>*/}
    </>
  )
}

export default Home
