import React, { useState } from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import { useDispatch } from 'react-redux';
import { fetchPosts } from './Redux/Contact';
import Near from './Near';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';

const Contact = () => {
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Address, setAddress] = useState('');
    const dispatch = useDispatch();
    const handleKeyPress = (e) => {
        const charCode = e.which || e.keyCode;
        if (
            charCode < 48 ||
            charCode > 57 ||
            (charCode !== 8 && e.target.value.length >= 10)
        ) {
            e.preventDefault();
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('Name', Name);
        formData.append('Mobile', Mobile);
        formData.append('Email', Email);
        formData.append('Address', Address);
        // formData.append('language', 'English');
        dispatch(fetchPosts(formData));
        setEmail('');
        setName('');
        setAddress('');
        setMobile('');
    };

    return (
        <>
            {/* <DocumentTitle title='Contact Us | Agrawal Samaj Ujjain'> */}
            <Helmet>
                <meta name="description" content="Contact our hotel in Ujjain. Discover our history, mission, and values." />
                <title>Contact Us | Agrawal Samaj Ujjain</title>
                {/* Open Graph meta tags */}
                <meta property="og:title" content="Contact Us | Agrawal Samaj Ujjain" />
                <meta property="og:description" content="Contact our hotel in Ujjain. Discover our history, mission, and values." />
                <meta property="og:image" content="https://example.com/Contact-us.jpg" />
                <meta property="og:url" content="https://agrawalsamajujjain.com/contact" />
                <meta property="og:type" content="website" />

                {/* Twitter meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Us | Agrawal Samaj Ujjain" />
                <meta name="twitter:description" content="Contact our hotel in Ujjain. Discover our history, mission, and values." />
                <meta name="twitter:image" content="https://example.com/Contact-us.jpg" />

                {/* Additional meta tags */}
                <meta name="author" content="Agrawal Samaj Ujjain" />
                <meta name="keywords" content="hotel, luxury, Ujjain, history, mission, values" />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
            </Helmet>
            <Header />
            {/* <!----------------HERO SECTION------------------> */}
            <section className="breadcampwrap" style={{ "background": "url(./assets/images/Breadcamp.png)" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="breadcamp">
                            <h4>Welcome to Dharamshala</h4>
                            <h2>Contact us</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Est amet quisquam, ullam fugit architecto iusto consequatur. Nam omnis, quibusdam alias maiores rerum veniam archite</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Contact us --> */}
            <section className="contact form">
                <div className="container containergrid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-container">
                                <h1>Get In Touch</h1>
                                <form onSubmit={handleSubmit}>
                                    <input type="text" value={Name} name="first-name" placeholder="First Name" required onChange={(e) => setName(e.target.value)} />
                                    <input type="email" value={Email} name="email" placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)} />
                                    <input type="text" value={Mobile} minLength={10} onKeyPress={handleKeyPress} name="contact-number" placeholder="Enter Contact Number" required onChange={(e) => setMobile(e.target.value)} />
                                    <input type="text" value={Address} name="Address" placeholder="Address" required onChange={(e) => setAddress(e.target.value)} />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="map-container">

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.5440880164214!2d72.8384!3d19.076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTnCsDA0JzM3LjIiTiA3MsKwNTAnMjEuOCJF!5e0!3m2!1sen!2sin!4v1620556805123!5m2!1sen!2sin" width="700" height="300" style={{ "border": "0" }} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Near />
            <Footer />
            {/* </DocumentTitle> */}
        </>
    )
}

export default Contact
